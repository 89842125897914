import { render, staticRenderFns } from "./SupportList.vue?vue&type=template&id=21d56576&scoped=true"
import script from "./SupportList.vue?vue&type=script&lang=js"
export * from "./SupportList.vue?vue&type=script&lang=js"
import style0 from "./SupportList.vue?vue&type=style&index=0&id=21d56576&prod&lang=css"
import style1 from "./SupportList.vue?vue&type=style&index=1&id=21d56576&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d56576",
  null
  
)

export default component.exports